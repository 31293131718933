import { useCallback, useContext, useMemo } from "react";

import { useMsal } from "@azure/msal-react";
import { usePathname } from "next/navigation";

import { LOCALES } from "~/constants/i18n";
import { Params, Routes } from "~/constants/request";
import { AuthContext } from "~/context/auth/context";
import { RouterHistoryContext } from "~/context/router-history/context";
import { cleanUpSelectedStore } from "~/helpers/selected-store/clean-up-selected-store";
import { getB2cPolicies } from "~/services/msal/get-b2c-policies";
import { performLoginRequest } from "~/services/msal/perform-login-request";
import { Logger } from "~/utils/logger";
import { getBaseUrl } from "~/utils/url/get-base-url";

import { IAzureUserState } from "./types";

export interface UseLoginLogoutReturnType {
  startLogin: (
    afterLoginRedirectTo: string,
    isRegister?: boolean,
    startedFromUrl?: string,
  ) => void;
  startLogout: (showSessionExpiredPopup?: boolean, isPreviousPath?: boolean) => void;
  startChangePassword: (
    afterChangePasswordRedirectTo: string,
    startedFromUrl?: string,
  ) => void;
  startResetPassword: (
    afterResetPasswordRedirectTo: string,
    startedFromUrl?: string,
  ) => void;
  getAzureUserState: () => IAzureUserState;
}

enum SIGN_UP_SIGN_IN_TABS {
  REGISTER = "register",
  LOGIN = "login",
}

const AZURE_USER_STATE = "azureUserState";

export const useLoginLogout = (locale: LOCALES): UseLoginLogoutReturnType => {
  const pathname = usePathname();

  const { instance, accounts } = useMsal();

  const currentAccount = useMemo(() => accounts?.[0], [accounts]);

  const loginRedirectUri = useMemo(() => {
    return `${getBaseUrl(locale)}${Routes.LOGIN}`;
  }, [locale]);

  const setAzureUserState = (state: IAzureUserState) => {
    localStorage.setItem(AZURE_USER_STATE, JSON.stringify(state));
  };

  const getAzureUserState = (): IAzureUserState => {
    const state = localStorage.getItem(AZURE_USER_STATE);

    return state ? JSON.parse(state) : {};
  };

  const clearAzureUserState = () => {
    localStorage.removeItem(AZURE_USER_STATE);
  };

  const startLogin = useCallback(
    (afterLoginRedirectTo: string, isRegister = false, startedFromUrl?: string) => {
      setAzureUserState({
        startFrom: startedFromUrl ?? pathname,
        redirectTo: afterLoginRedirectTo,
      });

      instance
        .loginRedirect({
          ...performLoginRequest(locale),
          redirectUri: loginRedirectUri,
          extraQueryParameters: {
            ui_locales: locale,
            page_locale: locale,
            tab: isRegister
              ? SIGN_UP_SIGN_IN_TABS.REGISTER
              : SIGN_UP_SIGN_IN_TABS.LOGIN,
          },
        })
        .catch((e) => {
          clearAzureUserState();
          Logger.getLogger().error(e);
        });
    },
    [loginRedirectUri, instance, locale, pathname],
  );

  const authContext = useContext(AuthContext);

  const routerHistoryContext = useContext(RouterHistoryContext);

  const startLogout = useCallback(
    async (showSessionExpiredPopup = true, isPreviousPath = false) => {
      const redirectUri = getBaseUrl(locale) + Routes.HOME;
      const redirectQueryParams = showSessionExpiredPopup
        ? `?${Params.SESSION_EXPIRED_POPUP}=true`
        : "";

      const logoutRequest = {
        postLogoutRedirectUri: isPreviousPath
          ? routerHistoryContext?.previousPath + redirectQueryParams
          : redirectUri + redirectQueryParams,
      };

      cleanUpSelectedStore(authContext?.account);

      await instance
        .logoutRedirect(logoutRequest)
        .then(() => {
          instance.setActiveAccount(null);
          authContext?.resetAccount?.();
        })
        .catch((e) => {
          Logger.getLogger().error(e);
        });
    },
    [locale, currentAccount, instance, authContext?.resetAccount], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const startChangePassword = useCallback(
    (afterChangePasswordRedirectTo: string, startedFromUrl?: string) => {
      setAzureUserState({
        startFrom: startedFromUrl ?? pathname,
        redirectTo: `${afterChangePasswordRedirectTo}?${Params.CHANGE_PASSWORD_POPUP}=true`,
      });

      instance
        .loginRedirect({
          authority: getB2cPolicies(locale).authorities.changePassword.authority,
          ...performLoginRequest(locale),
          prompt: "none",
          redirectUri: loginRedirectUri,
          extraQueryParameters: {
            ui_locales: locale,
            page_locale: locale,
          },
        })
        .catch((e) => {
          clearAzureUserState();
          Logger.getLogger().error(e);
        });
    },
    [locale, loginRedirectUri, instance, pathname],
  );

  const startResetPassword = useCallback(
    (afterResetPasswordRedirectTo: string, startedFromUrl?: string) => {
      setAzureUserState({
        startFrom: startedFromUrl ?? pathname,
        redirectTo: `${afterResetPasswordRedirectTo}?${Params.RESET_PASSWORD_POPUP}=true`,
        restartLoginAfterCancel: true,
      });

      instance
        .loginRedirect({
          authority: getB2cPolicies(locale).authorities.resetPassword.authority,
          ...performLoginRequest(locale),
          redirectUri: loginRedirectUri,
          extraQueryParameters: {
            ui_locales: locale,
            page_locale: locale,
          },
        })
        .catch((e) => {
          clearAzureUserState();
          Logger.getLogger().error(e);
        });
    },
    [locale, loginRedirectUri, instance, pathname],
  );

  return {
    startLogin,
    startLogout,
    startChangePassword,
    startResetPassword,
    getAzureUserState,
  };
};
