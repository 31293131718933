import { useCallback, useMemo } from "react";

import { useMsal } from "@azure/msal-react";

import { LOCALES } from "~/constants/i18n";
import { Routes } from "~/constants/request";
import { getClientId } from "~/services/msal/get-client-id";
import { getBaseUrl } from "~/utils/url/get-base-url";

export interface UseAcquireTokenSilentReturnType {
  acquireTokenSilent: () => Promise<string | null>;
}

export const useAcquireTokenSilent = (
  locale: LOCALES,
): UseAcquireTokenSilentReturnType => {
  const { instance, accounts } = useMsal();

  const currentAccount = useMemo(() => accounts?.[0], [accounts]);

  const blankPageRedirectUri = `${getBaseUrl()}${Routes.BLANK}`;

  const acquireTokenSilent = useCallback(async (): Promise<string | null> => {
    const clientId = getClientId(locale);
    const accessTokenRequest = {
      scopes: typeof clientId === "string" ? [clientId] : [],
      account: currentAccount,
      prompt: "none",
      redirectUri: blankPageRedirectUri,
    };

    await instance.initialize();

    return new Promise((resolve, reject) => {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          resolve(accessTokenResponse.idToken);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, [currentAccount, instance, locale, blankPageRedirectUri]);

  return {
    acquireTokenSilent,
  };
};
